import DumpExcelService from "../services/dumpExcel.service";

export const dumpExcelEmployees = (formData, query) => async () => {
  try {
    const res = await DumpExcelService.dumpExcelEmployees(formData, query);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};
