import {
  USER_LEAVE_FETCHING,
  USER_LEAVE_FAILED,
  USER_LEAVE_SUCCESS,
  LEAVE_TYPE_FETCHING,
  LEAVE_TYPE_FAILED,
  LEAVE_TYPE_SUCCESS,
  LEAVEEMPLOYEES_WITHDRAW_FETCHING,
  LEAVEEMPLOYEES_WITHDRAW_FAILED,
  LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
  LEAVE_PATTERN_FETCHING,
  LEAVE_PATTERN_FAILED,
  LEAVE_PATTERN_SUCCESS,
  LEAVE_FETCHING,
  LEAVE_FAILED,
  LEAVE_SUCCESS,
  LEAVE_AVAILABLE_FETCHING,
  LEAVE_AVAILABLE_FAILED,
  LEAVE_AVAILABLE_SUCCESS
} from "./types";

import LeaveService from "../services/leave.service";

export const getAllLeaveBalanceByEmployeeId = (year) => async (dispatch) => {
  try {
    const res = await LeaveService.getAllLeaveBalanceByEmployeeId(year);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err;
  }
};

export const getAllLeaveEmployeesBy = (filter, search) => async (dispatch) => {
  try {
    const res = await LeaveService.getAllLeaveEmployeesBy(filter, search);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err;
  }
};

export const getAllLeaveWithDrawBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FETCHING,
    });
    const res = await LeaveService.getAllLeaveWithDrawBy(filter, search);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const allLeaveEmployeeByManager = (search) => async (dispatch) => {
  try {
    const res = await LeaveService.allLeaveEmployeeByManager(search);
    if (res) {
      dispatch({
        type: USER_LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: USER_LEAVE_FAILED,
    });
    return err;
  }
};

export const allLeaveType = () => async (dispatch) => {
  try {
    const res = await LeaveService.allLeaveType();
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    return err;
  }
};

export const addLeaveType = (id, formData) => async (dispatch) => {
  try {
    console.log(formData);
    const res = await LeaveService.addLeaveType(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addLeaveEmployees = (formData) => async () => {
  try {
    const res = await LeaveService.addLeaveEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const updateApproveLeaveEmployeeBy = (filter, formData) => async () => {
  try {
    const res = await LeaveService.updateApproveLeaveEmployeeBy(
      filter,
      formData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const withdrawLeaveEmployee = (filter, formData) => async () => {
  try {
    const res = await LeaveService.withdrawLeaveEmployee(filter, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const uploadFileLeaveType = (formData) => async () => {
  try {
    const res = await LeaveService.uploadFileLeaveType(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const allLeaveTypeByCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_TYPE_FETCHING,
    });
    const res = await LeaveService.allLeaveTypeByCompany();
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
  }
};

export const getPatternForLeave = (searchDate) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_PATTERN_FETCHING,
    });
    const res = await LeaveService.getPatternForLeave(searchDate);
    if (res) {
      dispatch({
        type: LEAVE_PATTERN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: LEAVE_PATTERN_FAILED,
    });
  }
};

export const getAllVendorCompanyLeaveType = (data) => async (dispatch) => {
  try {
    const res = await LeaveService.getAllVendorCompanyLeaveType(data);
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    return err;
  }
};

export const uploadFileVendorCompanyLeaveType = (formData) => async () => {
  try {
    const res = await LeaveService.uploadFileVendorCompanyLeaveType(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const addVendorCompanyLeaveType = (formData) => async (dispatch) => {
  try {
    const res = await LeaveService.addVendorCompanyLeaveType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getLeaveById = (id) => async (dispatch) => {
  try {
    const res = await LeaveService.getLeaveById(id);
    if (res) {
      dispatch({
        type: LEAVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const editVendorCompanyLeaveType = (formData) => async (dispatch) => {
  try {
    const res = await LeaveService.editVendorCompanyLeaveType(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteVendorCompanyLeaveType = (id) => async (dispatch) => {
  try {
    const res = await LeaveService.deleteVendorCompanyLeaveType(id);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getLeaveAvailable = (query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_AVAILABLE_FETCHING,
    });
    const res = await LeaveService.getLeaveAvailable(query);
    if (res) {
      dispatch({
        type: LEAVE_AVAILABLE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_AVAILABLE_FAILED,
    });
    console.log(err);
  }
};

export const withdrawEmployeeLeaveRequest = (idLeave) => async () => {
  try {
    const res = await LeaveService.withdrawEmployeeLeaveRequest(idLeave);
    return res;
  } catch (error) {
    return error.response;
  }
}
