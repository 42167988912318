import React, { useState, useEffect, Fragment } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Profile from "./personal-info";
import Transfer from "./transfer";
import Promotion from "./promotion";
import PaymentChange from "./payment-change";
import Terminate from "./terminate";
import Probation from "./probation";
import EducationExperience from "./education-experience";
import LocationTab from "./address-contact";
import WorkInfo from "./work-info";
import Employment from "./employment";
import Performance from "./performance";
import Leave from "./leave";
import AdditionDeduction from "./addition-deduction";
import ProvidentFund from './provident-fund';
import EmployeeContract from './employee-contract';
import ResetPassword from './reset-password';

import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Menu,
  MenuItem,
  Popper,
  Box,
  Tabs,
  Tab,
  Paper,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DocFullPage from "../fileDoc/DocFullPage";
import SlideFullPage from "../fileDoc/SlideFullPage";

import DialogDownloadFile from "./DialogDownloadFile";

import { getEmployeesProfileByIdForCompany } from "../../../../../actions/employee";
import { getUserProfile } from "../../../../../actions/user";

const useStyles = makeStyles((theme) => ({
  background: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#ff3c19",
  },
  heading: {
    flexBasis: "50%",
    width: "50%",
    marginTop: 15,
  },
  panel: {
    borderRadius: "10px 10px 0px 0px",
  },
  contentContainer: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  keyAdjustContainer: {
    backgroundColor: "#F5F7FA",
    padding: theme.spacing(3),
    borderRadius: "10px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  textPrimary: {
    color: "#16243D",
    fontWeight: 600,
  },
  textSecondary: {
    color: "#58647A",
    fontWeight: 600,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
}));

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  paddingTop: "120px",
  paddingBottom: "120px",
  "& .MuiAvatar-root": {
    width: "180px",
    height: "180px",
  },
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .NavButtonDownload": {
    width: "fit-content",
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const StyleIconButton = styled(IconButton)({
  color: "#007afe",
});

const StyledWrapAction = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 16,
  ["@media only screen and (max-width: 600px)"]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  ["@media only screen and (max-width: 600px)"]: {
    marginBottom: 8,
  },
  "& .MuiButtonBase-root": {
    borderRadius: 16,
    width: 160,
  },
  "& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
    borderColor: "#1976d2",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    value: index
  };
}

export const Adjust = [
  { id: 1, title: "Transfer Out" },
  { id: 2, title: "Transfer In" },
  { id: 3, title: "Probation **ไม่มี" },
  { id: 4, title: "Promotion" },
  { id: 5, title: "Secondment **ไม่มี" },
  { id: 6, title: "Payment Change" },
  { id: 7, title: "Terminate" },
];

function KeyAddition() {
  useTitle("Add Key Addition - HR CORE DATA");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  let { id } = useParams();

  const [tabs, setTabs] = useState(0);
  const [groupTap, setGroupTap] = useState("personal");
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeDownload, setTypeDownload] = useState(null);
  const [openDialogDownloadFile, setOpenDialogDownloadFile] = useState(false);
  const idMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (userProfile) {
      dispatch(getEmployeesProfileByIdForCompany(id));
    }
  }, [userProfile]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTypeDownload(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={idMenuOpen}
      onClose={handleMenuClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {/* <PDFDownloadLink
        style={{
          color: "black",
          textDecoration: "none",
        }}
        document={<DocFullPage data={employeeProfile} type={typeDownload} />}
        fileName={typeDownload ? `${typeDownload}.pdf` : "resume.pdf"}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon style={{ color: "red" }}>
            <i className="fa-solid fa-file-pdf" />
          </ListItemIcon>
          PDF
        </MenuItem>
      </PDFDownloadLink> */}
      <MenuItem
        onClick={() => {
          handleMenuClose();
          SlideFullPage(employeeProfile, typeDownload);
        }}
      >
        <ListItemIcon style={{ color: "orange" }}>
          <i className="fa-solid fa-file-powerpoint" />
        </ListItemIcon>
        PowerPoint
      </MenuItem>
    </Menu>
  );

  const handleTabsChange = (e, newValue) => {
    setTabs(newValue);
  };

  const handleChangeGroupTab = (event, newAlignment) => {
    if (newAlignment !== null) {
      if (newAlignment === "work") {
        setTabs(8);
      } else {
        setTabs(0);
      }
      setGroupTap(newAlignment);
    }
  };

  const handleClickDownload = (event, type) => {
    setAnchorEl(event.currentTarget);
    setTypeDownload(type);
  };

  const handleOpenDialogDownloadFile = () => {
    setOpenDialogDownloadFile(true);
  };
  const handleCloseDialogDownloadFile = () => {
    setOpenDialogDownloadFile(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {employeeProfile && (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Profile dataEmployee={employeeProfile} />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <StyledWrapAction>
                  <StyledToggleButtonGroup
                    style={{ alignItems: "flex-end" }}
                    color="primary"
                    value={groupTap}
                    exclusive
                    onChange={handleChangeGroupTab}
                  >
                    <ToggleButton
                      value="personal"
                      style={{ height: "fit-content" }}
                    >
                      ข้อมูลพื้นฐาน
                    </ToggleButton>
                    <ToggleButton
                      value="work"
                      style={{ height: "fit-content" }}
                    >
                      เปลี่ยนแปลงข้อมูล
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <div className="NavButtonDownload">
                    <ButtonBlue
                      variant="contained"
                      startIcon={<FileDownloadIcon />}
                      onClick={handleOpenDialogDownloadFile}
                    >
                      ดาวน์โหลดไฟล์
                    </ButtonBlue>
                    {/*<ButtonBlue
                      size="small"
                      variant="contained"
                      className={classes.buttonDownload}
                      onClick={(event) =>
                        handleClickDownload(event, "fullPage")
                      }
                    >
                      Full one page resume
                    </ButtonBlue> */}
                  </div>
                </StyledWrapAction>
                {anchorEl && renderMenu}
                <Paper>
                  <Box style={{ width: "100%" }}>
                    {groupTap === "personal" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabs}
                          onChange={handleTabsChange}
                          aria-label="basic tabs example"
                          scrollButtons="auto"
                          variant="scrollable"
                        >
                          <Tab label={`${t("PersonalInfo")}`} {...a11yProps(0)} />
                          <Tab label={`${t("JobInfo")}`} {...a11yProps(1)} />
                          <Tab label={`${t("PaymentInfo")}`} {...a11yProps(2)} />
                          {/* <Tab
                            label={`${t("ProvidentFund")}`}
                            {...a11yProps(3)}
                          /> */}
                          <Tab
                            label={`${t("Education")}-${t("ExperienceInfo")}`}
                            {...a11yProps(4)}
                          />
                          <Tab label={t("performance")} {...a11yProps(5)} />
                          <Tab label={t("leaveAbsent")} {...a11yProps(6)} />
                          <Tab label={t("employeeContract")} {...a11yProps(7)} />
                        </Tabs>
                      </Box>
                    )}
                    {groupTap === "work" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabs}
                          onChange={handleTabsChange}
                          aria-label="basic tabs example"
                          scrollButtons="auto"
                          variant="scrollable"
                        >
                          <Tab label="โอน/ย้าย" value={8} {...a11yProps(8)} />
                          <Tab label="ทดลองงาน" value={9} {...a11yProps(9)} />
                          <Tab label="ปรับระดับ" value={10} {...a11yProps(10)} />
                          <Tab
                            label="ปรับค่าจ้าง"
                            value={11}
                            {...a11yProps(11)}
                          />
                          <Tab label="ลาออก" value={12} {...a11yProps(12)} />
                          <Tab
                            label="รายการจ่ายเพิ่ม/หักเงินประจำ"
                            value={13}
                            {...a11yProps(13)}
                          />
                          <Tab
                            label="รีเซ็ตรหัสผ่าน"
                            value={14}
                            {...a11yProps(14)}
                          />
                        </Tabs>
                      </Box>
                    )}

                    <TabPanel value={tabs} index={0}>
                      <LocationTab />
                    </TabPanel>
                    <TabPanel value={tabs} index={1}>
                      <WorkInfo 
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={2}>
                      <Employment 
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={3}>
                      <ProvidentFund />
                    </TabPanel>
                    <TabPanel value={tabs} index={4}>
                      <EducationExperience />
                    </TabPanel>
                    <TabPanel value={tabs} index={5}>
                      <Performance />
                    </TabPanel>
                    <TabPanel value={tabs} index={6}>
                      <Leave />
                    </TabPanel>
                    <TabPanel value={tabs} index={7}>
                      <EmployeeContract />
                    </TabPanel>
                    <TabPanel value={tabs} index={8}>
                      <Transfer />
                    </TabPanel>
                    <TabPanel value={tabs} index={9}>
                      <Probation />
                    </TabPanel>
                    <TabPanel value={tabs} index={10}>
                      <Promotion />
                    </TabPanel>
                    <TabPanel value={tabs} index={11}>
                      <PaymentChange />
                    </TabPanel>
                    <TabPanel value={tabs} index={12}>
                      <Terminate />
                    </TabPanel>
                    <TabPanel value={tabs} index={13}>
                      <AdditionDeduction />
                    </TabPanel>
                    <TabPanel value={tabs} index={14}>
                      <ResetPassword />
                    </TabPanel>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Container>
      <DialogDownloadFile
        open={openDialogDownloadFile}
        handleCloseDialog={handleCloseDialogDownloadFile}
      />
    </StyledRoot>
  );
}

export default KeyAddition;
