import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Grid,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Close, Edit, Save } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import CardHistoryLeave from "./CardHistoryLeave";
import DialogNewLeave from "./dialogNewLeave";

import {
  getEmployeeLeaveRequest,
  getLeaveQuota,
  getLeaveRequestAllYear,
} from "../../../../../../actions/employee";
import DialogEdit from "./DialogEdit";
import CardRequestLeave from "../../../../shared/leave/CardRequestLeave";
import DialogConfirmWithdraw from "../../../../shared/general/DialogConfirmWithdraw";
import DialogWithdrawRequestLeave from "../../../../shared/leave/DialogWithdrawRequestLeave";
import { getLeaveAvailable, withdrawEmployeeLeaveRequest } from "../../../../../../actions/leave";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import utils, { getRoundNumber } from "../../../../../../utils";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledCardDark = styled("div")({
  padding: 8,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledWrapHistory = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

const Leave = () => {

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList, isFetching: isFetchingLeaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);

  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dateSearch, setDateSearch] = useState(dayjs(today).format("YYYY"));

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  // const [paginationLeaveEmployeesList, setPaginationEmployeesList] = useState([]);
  
  const [paginationLeaveRequestConfig, setPaginationLeaveRequestConfig] = useState({
    count: 1,
    page: 1,
    data: [],
  })

  const [dialogDeleteLeaveRequestConfig, setDialogDeleteLeaveRequestConfig] = useState({
    isOpen: false,
    data: {},
  })

  const handleChangePageLeaveEmployeesList = (array, page) => {
    setPaginationLeaveRequestConfig(prev => ({
      ...prev,
      page: page,
      data: array.slice((page*10)-10, (page*10))
    }))
  }

  const fetchingLeaveEmployeesList = (year) => {
    dispatch(getEmployeeLeaveRequest(employeeProfile.idEmp, {
      start: `${year}-01-01`,
      end: `${year}-12-31`
    })).then(res => {
      if(res.status === 200){
        setPaginationLeaveRequestConfig(prev => ({
          ...prev,
          count: Math.ceil(res.data.length / 10),
          page: 1,
          data: res.data.slice((1*10)-10, (1*10))
        }))
      }
    })
  }

  const handleOpenDialogLeaveRequest = (data) => {
    console.log(data)
    setDialogDeleteLeaveRequestConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idLeave: data.idLeave,
        isFullDay: data.isFullDay,
        start: data.start,
        end: data.end,
        leaveGroupName: data.leaveGroupName,
      }
    }))
  }

  const handleCloseDialogDeleteLeaveRequest = () => {
    setDialogDeleteLeaveRequestConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleSubmitDialogDeleteLeaveRequest = async () => {
    console.log(dialogDeleteLeaveRequestConfig.data)
    if(dialogDeleteLeaveRequestConfig.data.idLeave){
      const result = await dispatch(withdrawEmployeeLeaveRequest(dialogDeleteLeaveRequestConfig.data.idLeave))
      if(result && result.status === 200) {
        handleCloseDialogDeleteLeaveRequest();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        }));
        fetchingLeaveEmployeesList(dateSearch)
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getLeaveQuota(employeeProfile.idEmp, null, dateSearch));
      dispatch(
        getLeaveRequestAllYear(
          dateSearch,
          employeeProfile.idEmp
        )
      );
      fetchingLeaveEmployeesList(dateSearch);
      dispatch(getLeaveAvailable({ date: dayjs(`${dateSearch}-01-01`).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
    }
  }, [employeeProfile]);

  const fetchingLeaveAvailable = () => {
    dispatch(getLeaveAvailable({ date: dayjs(`${dateSearch}-01-01`).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
  }

  const handleCloseDialogConfirmLeave = () => {
    setOpenNewDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const renderUseLeave = (leave) => {
    const employeeLeave = leaveEmployeesList.filter(
      (x) => x.idLeaveType === leave.idLeaveType
    );

    const sum = employeeLeave
      .filter((x) => x.isActive && x.isApprove !== 0 && (!x.withdraw || x.withdraw.isApprove !== 1))
      .reduce((accumulator, object) => {
        return accumulator + object.used;
      }, 0);

    if (employeeLeave.length === 0) {
      return 0;
    } else {
      return getRoundNumber(sum, 2);
    }
  };

  const leaveEmployeesListSort = useMemo(() => {
    if (leaveEmployeesList) {
      return [...leaveEmployeesList].sort(
        (a, b) => dayjs(b.start) - dayjs(a.start)
      );
    }
  }, [leaveEmployeesList]);

  const handleChangeYear = (event) => {
    setDateSearch(event.target.value);
    dispatch(getLeaveQuota(employeeProfile.idEmp, null, event.target.value));
    fetchingLeaveEmployeesList(event.target.value);
    dispatch(getLeaveAvailable({ date: dayjs(`${event.target.value}-01-01`).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
  };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <div>
            <StyledHeadLabel variant="body2" gutterBottom>
              {t("leaveAbsent")}
            </StyledHeadLabel>
            <div>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChangeYear}
                  value={dateSearch}
                >
                  <MenuItem value={2023}>2023</MenuItem>
                  <MenuItem value={2024}>2024</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={{ display: "flex", gap: 16 }}>
            <ButtonBlue
              size="small"
              startIcon={<Edit />}
              onClick={() => setOpenEdit(true)}
            >
              {t("Edit")}
            </ButtonBlue>
            <ButtonBlue
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => setOpenNewDialog(true)}
            >
              {t("CreateLeave")}
            </ButtonBlue>
          </div>
        </div>
        {employeeProfile && (
          <TableContainer
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "16px",
            }}
            sx={{
              "& .MuiTableRow-root:last-child": {
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                }
              }
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 250 }}>
                    {t("LeaveType")}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {t("LeaveRights")}/{t("CumulativeRights")} ({t("Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Used")} ({t("Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Remaining")} ({t("Days")})
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                // leaveEmployeesList &&
                //   leaveTypeList &&
                  leaveAvailable.length > 0 &&
                  leaveAvailable.map((leave) => {
                    return (
                      <TableRow key={leave.idLeaveType}>
                        <TableCell
                          // style={{ display: "flex", minHeight: "24.5px" }}
                        >
                          <Typography fontSize="14px">{leave.name}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {/* <Typography>
                            {leave.leaveValue}
                            {`${
                              leave.carry && leave.carry.carry
                                ? "/" + leave.carry.carry
                                : ""
                            }`}
                          </Typography> */}
                          {leave.leaveValue === null ? (
                            <Typography fontSize="14px">{t("Unlimited")}</Typography>
                          ) : (
                            <Typography fontSize="14px">
                              {`${utils.roundToTwo(leave.leaveValue)}`}
                              {`${leave.carryValue ? "/" + `${utils.roundToTwo(leave.carryValue)}` : ""
                                }`}
                              {/* {leave.isLeaveCompensate === 1 ? " "+t("Unit.ShortHours") : ""} */}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontSize="14px">{`${utils.roundToTwo(leave.leaveUsed)}`}</Typography>
                          {/* {renderUseLeave(leave)} */}
                        </TableCell>
                        <TableCell align="center">
                          {/* {getRoundNumber((leave.carry && leave.carry.carry
                            ? leave.carry.carry
                            : 0) +
                            leave.leaveValue -
                            renderUseLeave(leave), 2)} */}
                          {leave.leaveValue === null ? (
                            <Typography fontSize="14px">{t("Unlimited")}</Typography>
                          ) : (
                            <Typography align="center" fontSize="14px">
                              {`${utils.roundToTwo(leave.leaveRemain)}`}
                              {`${leave.carryRemain ? "/" + `${utils.roundToTwo(leave.carryRemain)}` : ""
                                }`}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledWrapBranch>

      <Box marginTop="16px">
        <Typography variant="h4" fontSize="22px" paddingBottom="16px">{t("LeaveHistory")}</Typography>

        {isFetchingLeaveEmployeesList && <Typography color="text.secondary" fontStyle="italic" align="center">{t("LoadingData")}...</Typography>}
        {!isFetchingLeaveEmployeesList && leaveEmployeesList.length === 0 && <Typography color="text.secondary" fontStyle="italic" align="center">{t("NoData")}</Typography>}
        {!isFetchingLeaveEmployeesList && leaveEmployeesList.length > 0 && <Fragment>
          <Box display="flex" justifyContent="flex-end" marginBottom="16px">
            <Pagination
              component="div"
              color="primary"
              count={paginationLeaveRequestConfig.count}
              shape="rounded"
              page={paginationLeaveRequestConfig.page}
              onChange={(_, newPage) => {
                handleChangePageLeaveEmployeesList(leaveEmployeesList, newPage)
              }}
            />
          </Box>
          <Grid container spacing={2}>
            {/* {leaveEmployeesListSort && leaveEmployeesListSort.map((item) => (
              <Grid item xs={12} md={6} key={item.idLeave}>
                <CardHistoryLeave data={item} />
              </Grid>
            ))} */}
            {paginationLeaveRequestConfig.data.map((item) => (
              <Grid item xs={12} md={6} key={item.idLeave}>
                <CardRequestLeave data={item} handleDeleteLeaveRequest={handleOpenDialogLeaveRequest}/>
              </Grid>
            ))}
          </Grid>
        </Fragment>}
      </Box>

      <DialogNewLeave
        open={openNewDialog}
        handleClose={handleCloseDialogConfirmLeave}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
        fetchingLeaveEmployeesList={() => {fetchingLeaveEmployeesList(dateSearch)}}
        fetchingLeaveAvailable={fetchingLeaveAvailable}
      />

      <DialogEdit open={openEdit} setOpenEdit={setOpenEdit} />

      <DialogWithdrawRequestLeave
        dialogConfig={dialogDeleteLeaveRequestConfig}
        handleSubmit={handleSubmitDialogDeleteLeaveRequest}
        handleClose={handleCloseDialogDeleteLeaveRequest}
      />

    </div>
  );
};

export default Leave;
