import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllLeaveBalanceByEmployeeId = (year) => {
  return httpClient.get(`/users/leave?year=${year}`);
};

const allLeaveType = () => {
  return httpClient.get(`/vendor/leave-type`);
};

const allLeaveEmployeeByManager = (search) => {
  return httpClient.get(
    `request/leave?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const updateApproveLeaveEmployeeBy = (filter, formData) => {
  return httpClient.put(`request/leave/approve?filter=${filter}`, formData);
};

const withdrawLeaveEmployee = (filter, formData) => {
  if (filter) {
    return httpClient.put(`request/leave/withdraw?filter=${filter}`, formData);
  } else {
    return httpClient.put(`request/leave/withdraw`, formData);
  }
};

const getAllLeaveWithDrawBy = (filter, search) => {
  if (search) {
    return httpClient.get(
      `request/leave/withdraw?filter=${filter}&start=${dayjs(
        search.start
      ).format("YYYY-MM-DD")}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(`request/leave/withdraw?filter=${filter}`);
  }
};

const addLeaveType = (id, formData) => {
  return httpClient.post(`/company/${id}/leave-type`, formData);
};

const addLeaveEmployees = (formData) => {
  return httpClient.post(`/employee/leave`, formData);
};

const uploadFileLeaveType = (formData) => {
  return httpClient.post(`/leave`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAllLeaveEmployeesBy = (filter, search) => {
  return httpClient.get(
    `leave?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const allLeaveTypeByCompany = () => {
  return httpClient.get(`company/leave-type`);
};

const getPatternForLeave = (searchDate) => {
  return httpClient.get(`/pattern/leave`, {
    params: {
      start: dayjs(searchDate.startDate).format("YYYY-MM-DD"),
      end: dayjs(searchDate.endDate).format("YYYY-MM-DD"),
      idEmp: searchDate.idEmp,
    },
  });
};

const getAllVendorCompanyLeaveType = (data) => {
  return httpClient.get(`/leave-type`, {
    params: data,
  });
};

const uploadFileVendorCompanyLeaveType = (formData) => {
  return httpClient.post(`/leave/upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addVendorCompanyLeaveType = (formData) => {
  return httpClient.post(`/leave/add`, formData);
};

const getLeaveById = (id) => {
  return httpClient.get(`/leave/${id}`);
};

const editVendorCompanyLeaveType = (formData) => {
  return httpClient.put(`/leave/edit`, formData);
};

const deleteVendorCompanyLeaveType = (id) => {
  return httpClient.post(`/leave/delete/${id}`);
};

const getLeaveAvailable = (query) => {
  return httpClient.get(`leave/leave-available`, { params: query });
}

const withdrawEmployeeLeaveRequest = (idLeave) => {
  return httpClient.post(`/leave/request/${idLeave}/withdraw`)
}

const getLeaveRoundList = (query) => {
  return httpClient.get(`leave/leave-round-list`, { params: query });
}

export default {
  getAllLeaveBalanceByEmployeeId,
  allLeaveEmployeeByManager,
  updateApproveLeaveEmployeeBy,
  withdrawLeaveEmployee,
  getAllLeaveWithDrawBy,
  allLeaveType,
  addLeaveType,
  addLeaveEmployees,
  uploadFileLeaveType,
  getAllLeaveEmployeesBy,
  allLeaveTypeByCompany,
  getPatternForLeave,
  getAllVendorCompanyLeaveType,
  uploadFileVendorCompanyLeaveType,
  addVendorCompanyLeaveType,
  getLeaveById,
  editVendorCompanyLeaveType,
  deleteVendorCompanyLeaveType,
  getLeaveAvailable,
  withdrawEmployeeLeaveRequest,
  getLeaveRoundList
};
