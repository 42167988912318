import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

import DialogEdit from "./DialogEdit";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const Employment = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { t, i18n } = useTranslation();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");
  const [showPersonalId, setShowPersonalId] = useState(false);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeTimeToDate = (time) => {
    let splitTime = time.split(":");
    return new Date().setHours(splitTime[0], splitTime[1], splitTime[2]);
  };

  const calculateHours = (shift) => {
    let timeIn = dayjs(changeTimeToDate(shift.timeOut));
    return timeIn.diff(changeTimeToDate(shift.timeIn), "hour");
  };

  const renderCalculate = () => {
    if (employeeProfile.shift.workingHours) {
      var hours = employeeProfile.shift.workingHours / 60;
      if (employeeProfile.shift.breakTime === 0) {
        hours = hours - 1;
      }
      return hours;
    } else {
      return "-";
    }
  };

  const handleClickPayment = () => {
    changeGroupTap("work");
    changeTabs(10);
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("WorkingTimeInfo")}
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleClick("charge")}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkingType")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.workingType
                ? employeeProfile.workingType.toUpperCase()
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("ShiftType")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift
                ? employeeProfile.shift.nameShiftGroup
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("ShiftName")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift ? employeeProfile.shift.nameShift : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkDay")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift ? employeeProfile.shift.workDay : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkTimePerDay")}
            </StyledHeadLabel>
            <Typography variant="h6">{`${
              employeeProfile.shift ? renderCalculate() : " - "
            }${t("Hrs")}/${t("Days")}`}</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("OperationFee")}(%)
            </StyledHeadLabel>
            <Typography variant="h6">
              {(employeeProfile.salary && employeeProfile.salary.chargeSalary)
                ? `${utils.numberWithCommas(parseFloat(employeeProfile.salary.chargeSalary || 0))}%`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("OperationFee")} OT(%)
            </StyledHeadLabel>
            <Typography variant="h6">
              {(employeeProfile.salary && employeeProfile.salary.chargeOt)
                ? `${utils.numberWithCommas(parseFloat(employeeProfile.salary.chargeOt || 0))}%`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("ChargeHireFee")}{" "}
              {`${
                (employeeProfile.salary && employeeProfile.salary.isPercentChargeHire)
                  ? "(%)"
                  : ""
              }`}
            </StyledHeadLabel>
            <Typography variant="h6">
              {(employeeProfile.salary && employeeProfile.salary.chargeHire)
                ? `${utils.numberWithCommas(parseFloat(employeeProfile.salary.chargeHire || 0))}${employeeProfile.salary.isPercentChargeHire ? "%" : ""}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("ChargeCompensation")} (%)
            </StyledHeadLabel>
            <Typography variant="h6">
              {(employeeProfile.salary && employeeProfile.salary.chargeCompensation)
                ? `${utils.numberWithCommas(parseFloat(employeeProfile.salary.chargeCompensation || 0))}%`
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickPayment}
          >
            {t("PaymentChange")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PaymentTypeName")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentTypeName
                ? employeeProfile.paymentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Salary")}</StyledHeadLabel>
            <div className="salary">
              <div
                className="wrap-show-hide-personalId"
                onMouseEnter={() => setShowPersonalId(true)}
                onMouseLeave={() => setShowPersonalId(false)}
              >
                {showPersonalId ? (
                  <StyledTextValue variant="h6" align="center">
                    {employeeProfile.salaryValue
                      ? utils.numberWithCommas(employeeProfile.salaryValue)
                      : "-"}
                  </StyledTextValue>
                ) : (
                  <StyledTextValue className="dot" variant="h6" align="center">
                    {[...Array(6).keys()].map((_, index) => (
                      <i key={index} className="fas fa-times"></i>
                    ))}
                  </StyledTextValue>
                )}
              </div>
              <Typography color="text.secondary">
                {employeeProfile.paymentTypeName
                  ? `${t("Per")}${
                      employeeProfile.idPaymentType === 1 ||
                      employeeProfile.idPaymentType === 5
                        ? t("Day")
                        : t("Month")
                    }`
                  : "-"}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PaymentRound")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentRound
                ? employeeProfile.paymentRound
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          Cost Center
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleClick("cost")}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Center Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.costCenterCharge
                ? employeeProfile.costCenterCharge
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Element Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.costElementCharge
                ? employeeProfile.costElementCharge
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("BankInfo")}
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleClick("bank")}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("BankName")}/{t("BookBankBranch")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookBank ? employeeProfile.bookBank : "-"}
          <Typography component="span" color="text.secondary">
            {employeeProfile.bookBankBranch
              ? ` (${employeeProfile.bookBankBranch})`
              : ""}
          </Typography>
        </Typography>
      </div>
      <div style={{ marginBottom: 16 }}>
        <StyledHeadLabel color="text.secondary">
          {t("BookBankID")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookBankId ? employeeProfile.bookBankId : "-"}
        </Typography>
      </div>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("BankFiles")}
        </StyledHeadLabel>
        <div></div>
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankPayneeName")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBank_Name
            ? employeeProfile.reportBank_Name
            : "-"}
        </Typography>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankName")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBank_BankName
            ? employeeProfile.reportBank_BankName
            : "-"}
        </Typography>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankID")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBank_BankId
            ? employeeProfile.reportBank_BankId
            : "-"}
        </Typography>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBookBankID")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBank_BookBankId
            ? employeeProfile.reportBank_BookBankId
            : "-"}
        </Typography>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankRef")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBank_Ref
            ? employeeProfile.reportBank_Ref
            : "-"}
        </Typography>
      </div>
      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </StyledRoot>
  );
};

export default Employment;
