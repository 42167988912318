import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, Typography, Container, Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";

//import { getAllUsers } from "../../../../actions/user";
import { menuAppVendor } from "../../assets/data/menu-app";

const StyledWrapMenuApp = styled("div")(({}) => ({
  paddingBottom: 80,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .app-list": {
    display: "grid",
    gridTemplateRows: "1fr auto auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateRows:
        "1fr auto auto auto auto auto auto auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    ["@media only screen and (min-width:600px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (min-width:768px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media only screen and (min-width:992px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media only screen and (min-width:1200px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },

    "& .app-item": {
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledCard = styled(Card)({
  //width: 150,
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 22,
      lineHeight: 1.2,
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 150,
      height: 100,
      marginBottom: 16,
      "& img": {
        objectFit: "contain",
      },
    },
  },
});

const MenuApp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeList } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    //dispatch(getAllUsers());
  }, []);

  const handleFilterMenu = () => {
    if (menuAppVendor && userProfile) {
      if (userProfile.isSuperAdmin === 0) {
        if (
          userProfile.confidentialData.length === 1 &&
          (userProfile.confidentialData[0].idCompany === null ||
            userProfile.confidentialData[0].idCompany !== null) &&
          userProfile.confidentialData[0].isPayroll === 0
        ) {
          return menuAppVendor.filter((x) => {
            if (x.link.includes("payroll") || x.link.includes("billing")) {
            } else {
              return x;
            }
          });
        } else {
          const foundIsPayroll = userProfile.confidentialData.find(
            (x) => x.isPayroll === 1
          );

          if (foundIsPayroll) {
            
            return menuAppVendor;
          } else {
            return menuAppVendor.filter((x) => {
              if (x.link.includes("payroll") || x.link.includes("billing")) {
              } else {
                return x;
              }
            });
          }
        }
      } else {
        return menuAppVendor;
      }
    } else {
      return [];
    }
  };

  return (
    <Fragment>
      {employeeList && (
        <StyledWrapMenuApp>
          <div className="header">
            <Typography color="text.secondary">{t("ShortcutMenu")}</Typography>
          </div>
          <div className="app-list">
            {handleFilterMenu().map((value, index) => (
              <StyledCard
                key={index}
                onClick={() => history.push(value.link)}
                className={value.class}
              >
                <div className="inner">
                  <Avatar variant="rounded" src={value.icon} />
                  <div>
                    <Typography variant="subtitle" gutterBottom>
                      {t(value.name)}
                    </Typography>
                  </div>
                </div>
              </StyledCard>
            ))}
          </div>
        </StyledWrapMenuApp>
      )}
    </Fragment>
  );
};

export default MenuApp;
