import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { Box, Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DialogEdit from "./DialogEdit";
import { getEmployeePersonnelLevelChange } from "../../../../../../actions/employee";
import dayjs from "dayjs";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

function Promotion(props) {

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: employeePersonnelLevelChange } = useSelector((state) => state.employeePersonnelLevelChange);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  useEffect(() => {
    dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmp))
  }, [])

  return (
    <div>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer(true)}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StyledHeadLabel color="text.secondary">
            {t("LevelGroup")} ({t("Current")})
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.personnelGroupLevelName
              ? employeeProfile.personnelGroupLevelName
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <StyledHeadLabel color="text.secondary">
            {t("Level")} ({t("Current")})
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.personnelLevelName
              ? employeeProfile.personnelLevelName
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <StyledDivider />
      <Box marginBottom="16px">
        <StyledHeadLabel color="text.secondary">
          {t("ChangeLog")}
        </StyledHeadLabel>
      </Box>

      <Grid container spacing={2}>
        {employeePersonnelLevelChange.length === 0 && (
          <Grid item xs={12}>

          </Grid>
        )}
        {employeePersonnelLevelChange.length > 0 && (
          <Fragment>
            {employeePersonnelLevelChange.map(item => (
              <Grid key={item.idPersonnelLevel} item xs={12}>
                <Box>
                  <Typography fontWeight="600" marginBottom="8px">
                    [{dayjs(item.start).locale(i18n.language === "th"? "th": "en").format(`DD MMMM ${i18n.language === "th"? "BBBB": "YYYY"}`)}{item.end? `- ${dayjs(item.end).locale(i18n.language === "th"? "th": "en").format(`DD MMMM ${i18n.language === "th"? "BBBB": "YYYY"}`)}`: ""}] ระดับ {item.levelName}
                  </Typography>
                  <Typography fontSize="14px" marginBottom="8px">{t("LevelGroup")}: {item.groupLevelName}</Typography>
                  <Typography fontSize="14px" marginBottom="8px">{t("Description")}:</Typography>
                  <Typography fontSize="14px" color="text.secondary" marginBottom="8px" whiteSpace="pre-line">{item.remark? item.remark: "-"}</Typography>
                  <Typography fontSize="14px" align="right" color="text.secondary" fontStyle="oblique">วันที่แก้ไขข้อมูล {dayjs(item.updateDate).format("D MMMM YYYY HH.mm น.")}</Typography>
                </Box>
                <StyledDivider />
              </Grid>
            ))}
          </Fragment>
        )}
      </Grid>

      <DialogEdit open={openEdit} toggleDrawer={toggleDrawer} />
  
    </div>
  );
}

export default Promotion;
