import React, { Fragment, useState, Suspense, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import ScrollToTop from "./helpers/scrollToTop";

import Container from "@mui/material/Container";

import Login from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/register";
import Landing from "./components/pages/landing";
import GpsCheckInOutPage from "./components/pages/line/gpsCheckInOut";
import TodayCheckPage from "./components/pages/line/today-check";
import QRCodeCheckInOut from "./components/pages/line/QRCodeCheckInOut";
import ContactUs from "./components/pages/contactus";
import { UnauthorizedPage, NotFoundPage } from "./components/pages/error";

import HomePage from "./components/pages/homepage.js";
import ProfilePage from "./components/pages/shared/pages/profile";
import ChangePassword from "./components/pages/change-password";
import SummaryOTShiftPage from "./components/pages/summaryOTShift";

// import HomeUserPage from "./components/pages/user/homepage";

import CustomerPage from "./components/pages/ant-team/customer";

import Notification from "./components/pages/NotificationPage";
import OverviewUserPage from "./components/pages/user/overview";
import Timeline from "./components/pages/user/timeline/Timeline";
import Leavelist from "./components/pages/user/leave/Leavelist";
import Requestlist from "./components/pages/user/requestlist/Requestlist";
import Overtimereport from "./components/pages/user/overtime";
import PlanYearUser from "./components/pages/user/planyear";
import SalarySlip from "./components/pages/user/salary/paySlip";
import TaxDeduction from "./components/pages/user/salary/tax/TaxDeduction";
import TaxList from "./components/pages/user/salary/tax/TaxList";
import CoursesRegister from "./components/pages/user/training/course";
import ReportTax from "./components/pages/user/report/reportTax";
import PND1Kor from "./components/pages/user/report/reportTax/PND1Kor";
import TAWI_50 from "./components/pages/user/report/reportTax/50TAWI";
import PND91 from "./components/pages/user/report/reportTax/PND91";
import SummaryTime from "./components/pages/user/summaryTimeAndSalary";
import MyDocument from "./components/pages/user/my-document";
import VoyageRecord from "./components/pages/user/voyage-record";
import ManualTimesheet from "./components/pages/user/manual-timesheet";
import ExpenseUserCreatePage from "./components/pages/user/expense/create";
import ExpenseUserEditPage from "./components/pages/user/expense/edit";
import ExpenseUserViewPage from "./components/pages/user/expense/view";

//Main
import AccessAreaPage from "./components/pages/main-page/AccessArea";
import AccessTime from "./components/pages/main-page/AccessTime";
import ExpensePage from "./components/pages/main-page/expense/expense.js";
import ExpenseDetailPage from "./components/pages/main-page/expense/detail.js";
import ExpenseHistoryPage from "./components/pages/main-page/expense/history.js";

//Manager
import Overview from "./components/pages/manager/overview";
import ApproveList from "./components/pages/manager/approveList";
import HistoryApproveList from "./components/pages/manager/historyApproveList";
import OTMonitoring from "./components/pages/manager/otMonitoring";
import TimeManagementManager from "./components/pages/manager/timeManagement";
import DashboardOT from "./components/pages/manager/dashboard/ot/DashboardOT";
import DashboardWorkingTime from "./components/pages/manager/dashboard/workingTime/DashboardWorkingTime";
import DashboardCost from "./components/pages/manager/dashboard/cost/DashboardCost";
import SwitchShiftEmployeePage from "./components/pages/manager/employee/switchShift";
import ManagerEmployeePage from "./components/pages/manager/employee/employeePage";
import ManagerEmployeeProfilePage from "./components/pages/manager/employee/profile";

// Admin
import {
  AdminDashboardCost,
  AdminDashboardOt,
  AdminDashboardWorkingTime,
  SettingPage,
  EmployeesPage,
  AdminEmployeeRegisterPage,
  EmployeeProfilePage,
  OverviewPage,
  AdminOtMonitoring,
  ShiftPage,
  ShiftAddNewPage,
  ShiftListEmployeePage,
  PlanYearPage,
  ManPowerPage,
  TeamManPowerPage,
  RequestPage,
  RequestInformationPage,
  CandidateProfilePage,
  RequestFormPage,
  CompanyProfilePage,
  SelectKey,
  KeyAddition,
  TimeManagementPage,
  ApporveStatusListPage,
  HistoryApproveListPage,
  CourseDetailAdmin,
  CoursePageAdmin,
  BillingPage,
  BillingDetailPage,
  ContractVendor,
  ContractDetailVendor,
  AccessAreaAdmin,
  AccessAreaDetailAdmin,
  AdminSummaryOTPage,
  ContractDashboard,
} from "./components/pages/admin";

import CourseDetail from "./components/pages/training/course/detail";
import CoursePage from "./components/pages/training/course";

//Vendor
import ChangePasswordVendor from "./components/pages/change-password";
import EmployeePage from "./components/pages/vendor/employee/employeePage";
import VendorProfilePage from "./components/pages/vendor/profile";
import OverviewVendor from "./components/pages/vendor/overview";
import SourcingVendorPage from "./components/pages/vendor/vendor";
import RequestVendorInformationPage from "./components/pages/vendor/vendor/information";
import EmployeeProfilePageVendor from "./components/pages/vendor/employee/profile";
import KeyAdditionVendor from "./components/pages/vendor/employee/keyAdditions/KeyAddition";
import EmployeeRegisterPage from "./components/pages/vendor/employee/addEmployee";
import ApporveStatusListVendorPage from "./components/pages/vendor/approveList";
import HistoryApporveStatusListVendorPage from "./components/pages/vendor/historyApproveList";
import TimeManagementVendorPage from "./components/pages/vendor/timeManagement";
import TimelineEmployeesPage from "./components/pages/vendor/timeManagement/individual";
import CourseTrainingVendor from "./components/pages/vendor/training/course";
import CourseDetailVendor from "./components/pages/vendor/training/course/detail";
import ProfileVendor from "./components/pages/vendor/profile";
import ShiftVendorPage from "./components/pages/vendor/shift";
import ShiftAddNewVendorPage from "./components/pages/vendor/shift/addShift";
import ShiftListEmployeeVendorPage from "./components/pages/vendor/shift/listEmployee";
import PlanYearVendorPage from "./components/pages/vendor/shift/planYear";
import SettingVendorPage from "./components/pages/vendor/setting";
import BillingVendorPage from "./components/pages/vendor/billing";
import BillingDetailVendorPage from "./components/pages/vendor/billing/detail";
import ContractCompany from "./components/pages/vendor/contract-company";
import ContractEmployee from "./components/pages/vendor/contract-employee";
import ContractDetailEmployee from "./components/pages/vendor/contract-employee/contract-detail";
import ContractDetailCompany from "./components/pages/vendor/contract-company/detail";
import AnimationBackdrop from "./components/pages/shared/general/Backdrop";
import PayRunsPage from "./components/pages/vendor/payroll/pay-runs";
import PayrollSummaryYearPage from "./components/pages/vendor/payroll/summary";
import PayrollAdditionDeductionPage from "./components/pages/vendor/payroll/addition-deduction";
import PayrollSettingPage from "./components/pages/vendor/payroll/setting-payroll";
import PayRunsEditPage from "./components/pages/vendor/payroll/pay-runs/edit";
import PayRunsNewPage from "./components/pages/vendor/payroll/pay-runs/new";
import DocumentsVendorPage from "./components/pages/vendor/report";
import SummaryTimeVendorPage from "./components/pages/vendor/summaryTime";
import PND91AND50tawi from "./components/pages/vendor/report/official-documents/PND91-50tawi";
import AddEmployeePage from "./components/pages/vendor/employee/addEmployeeNew";
import TravelRecords from "./components/pages/vendor/travel-records";
import AccessAreaVendor from "./components/pages/vendor/access-area";
import CreateBilling from "./components/pages/vendor/billing/create";

//Accountant
import ExpenseExport from "./components/pages/admin-accountant/expense-export/index.js";
import ReportListsPage from "./components/pages/user/governmentReport/report-list";
import GovernmentReportIndex from "./components/pages/user/governmentReport";
import AddOrEditContractEmployeeForm from "./components/pages/vendor/contract-employee/addOrEditContractEmployeeForm.js";
import LeaveAbsentLate from "./components/pages/vendor/report/summarize-absent-late/index.js";
import TaxSummaryPage from "./components/pages/vendor/payroll/tax-summary/index.js";

// Report
import SummaryOTShift from "./components/pages/vendor/report/summary-ot-shift/index.js";
import DashboardOTList from "./components/pages/vendor/report/summary-hour-wage-ot/index.js";
import QuotaLeaveList from "./components/pages/vendor/report/quoto-leave-list.js";
import NewEmployeesReport from "./components/pages/vendor/report/new-employees.js";
import ResignEmployeeReport from "./components/pages/vendor/report/resigned-employees.js";
import ContractTermination from "./components/pages/vendor/report/employee-contract-termination.js";
import ProbationStatus from "./components/pages/vendor/report/probation/index.js";
import LevelingReport from "./components/pages/vendor/report/employee-leveling.js";
import employmentReport from "./components/pages/vendor/report/employment.js";
import TransferPosition from "./components/pages/vendor/report/employee-transfer-position.js";
import Payroll from "./components/pages/vendor/report/payroll.js";
import PayslipAdmin from "./components/pages/vendor/report/pay-slip/index.js";
import DashboardProvidentFund from "./components/pages/vendor/report/providentFund/index.js";
import DashboardTax from "./components/pages/vendor/report/dashboard-tax/index.js";

//Dashboard
// import DashboardVendor from "./components/pages/vendor/dashboard/index.js";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    // flexGrow: 1,
    // display: "flex",
    // justifyContent: "center",
    // padding: 0,
  },
}));

const Routers = () => {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPayroll, setIsPayroll] = useState(false);

  const RedirectLogin = () => (
    <Route>
      <Redirect to="/login" />
    </Route>
  );

  useEffect(() => {
    if (
      userProfile &&
      currentUser &&
      currentUser.roles.includes("ROLE_VENDOR") === true
    ) {
      if (
        userProfile.isSuperAdmin === 1 ||
        (userProfile.confidentialData.length === 1 &&
          (userProfile.confidentialData[0].idCompany === null ||
            userProfile.confidentialData[0].idCompany !== null) &&
          userProfile.confidentialData[0].isPayroll === 1)
      ) {
        setIsPayroll(true);
      } else if (userProfile.confidentialData.length > 1) {
        const foundIsPayroll = userProfile.confidentialData.find(
          (x) => x.isPayroll === 1
        );
        if (foundIsPayroll) {
          setIsPayroll(true);
        } else {
          setIsPayroll(false);
        }
      } else {
        setIsPayroll(false);
      }
    }
  }, [userProfile]);

  const LoginRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            [
              "ROLE_ADMIN",
              "ROLE_USER",
              "ROLE_TRAINING",
              "ROLE_VENDOR",
              "ROLE_MANAGER",
              "ROLE_ANT",
              "ROLE_ACCOUNT",
            ].some((r) => currentUser.roles.indexOf(r) >= 0) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AdminRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ADMIN") === true ? (
              <Fragment>
                {userProfile ? (
                  <Component {...props} />
                ) : (
                  <AnimationBackdrop open={!userProfile} />
                )}
              </Fragment>
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const VendorRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const VendorPayrollRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true && isPayroll ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const ManagerRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const UserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_USER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const VendorManagerUserRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true ||
            currentUser.roles.includes("ROLE_USER") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const TrainingRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_TRAINING") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AntTeamRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ANT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const AccountantRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const VendorManagerUserAccountantRoute = ({
    component: Component,
    ...rest
  }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true ||
            currentUser.roles.includes("ROLE_USER") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ||
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  const VendorManagerAccountantRoute = ({ component: Component, ...rest }) => {
    if (currentUser) {
      return (
        <Route
          {...rest}
          render={(props) =>
            currentUser.roles.includes("ROLE_VENDOR") === true ||
            currentUser.roles.includes("ROLE_MANAGER") === true ||
            currentUser.roles.includes("ROLE_ACCOUNT") === true ? (
              <Component {...props} />
            ) : (
              <Redirect to="/unauthorized" />
            )
          }
        />
      );
    } else {
      return <RedirectLogin />;
    }
  };

  return (
    <Container className={classes.content} maxWidth={false}>
      <Suspense fallback={<div>Loading..</div>}>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={["/"]}
            component={() =>
              currentUser ? <Redirect to="/home" /> : <Redirect to="/landing" />
            }
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/unauthorized" component={UnauthorizedPage} />
          <Route exact path="/gps-check" component={GpsCheckInOutPage} />
          <Route exact path="/today-check" component={TodayCheckPage} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route
            exact
            path="/qrcode-check/:idGroupGpsLocations"
            component={QRCodeCheckInOut}
          />

          <LoginRoute exact path="/home" component={HomePage} />
          <LoginRoute exact path="/profile" component={ProfilePage} />
          <LoginRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <LoginRoute
            exact
            path="/summary-time"
            component={SummaryOTShiftPage}
          />

          <AntTeamRoute exact path="/customer" component={CustomerPage} />

          <VendorManagerUserRoute
            exact
            path="/access_area"
            component={AccessAreaPage}
          />

          <VendorManagerUserRoute
            exact
            path="/access_time"
            component={AccessTime}
          />

          <Route exact path="/government-report" component={ReportListsPage} />
          <Route exact path="/document-report/:type" component={GovernmentReportIndex} />
          
          {/* User Route */}
          <UserRoute exact path="/notification" component={Notification} />
          {/* <UserRoute exact path="/profile" component={ProfilePage} /> */}
          <UserRoute exact path="/overview" component={OverviewUserPage} />
          <UserRoute exact path="/timeline" component={Timeline} />
          <UserRoute exact path="/leave" component={Leavelist} />
          <UserRoute exact path="/requestlist" component={Requestlist} />
          <UserRoute exact path="/plan" component={PlanYearUser} />
          <UserRoute exact path="/pay-slip" component={SalarySlip} />
          <UserRoute exact path="/taxdeduction" component={TaxDeduction} />

          {/* <UserRoute exact path="/summary-time" component={SummaryTime} /> */}
          <UserRoute
            exact
            path="/courses/register"
            component={CoursesRegister}
          />

          <UserRoute exact path="/taxlist" component={TaxList} />
          <UserRoute
            exact
            path="/overtime/overtimereport"
            component={Overtimereport}
          />

          <UserRoute exact path="/reporttax" component={ReportTax} />
          <UserRoute exact path="/pnd1kor" component={PND1Kor} />
          <UserRoute exact path="/50tawi" component={TAWI_50} />
          <UserRoute exact path="/pnd91" component={PND91} />

          <UserRoute exact path="/my-document" component={MyDocument} />
          <UserRoute exact path="/voyage-record" component={VoyageRecord} />
          <UserRoute
            exact
            path="/manual-timesheet"
            component={ManualTimesheet}
          />
          {/* <UserRoute exact path="/expense" component={ExpensePage} /> */}
          <UserRoute
            exact
            path="/expense/create"
            component={ExpenseUserCreatePage}
          />
          <UserRoute
            exact
            path="/expense/:typeExpense/:typeEdit/:idExpense"
            component={ExpenseUserEditPage}
          />
          <UserRoute
            exact
            path="/expense/:typeExpense/view/:idExpense/page"
            component={ExpenseUserViewPage}
          />

          {/* Manager Route */}
          <ManagerRoute exact path="/manager/overview" component={Overview} />
          {/* <ManagerRoute exact path="/change-password" component={ChangePasswordManager} /> */}
          <ManagerRoute exact path="/manager/approve" component={ApproveList} />
          <ManagerRoute
            exact
            path="/manager/history-approve"
            component={HistoryApproveList}
          />
          <ManagerRoute
            exact
            path="/manager/time"
            component={TimeManagementManager}
          />
          <ManagerRoute
            exact
            path="/manager/otmonitoring"
            component={OTMonitoring}
          />
          <ManagerRoute
            exact
            path="/manager/dashboard/ot"
            component={DashboardOT}
          />
          <ManagerRoute
            exact
            path="/manager/dashboard/workingtime"
            component={DashboardWorkingTime}
          />
          <ManagerRoute
            exact
            path="/manager/dashboard/cost"
            component={DashboardCost}
          />
          <ManagerRoute
            exact
            path="/manager/switch-shift"
            component={SwitchShiftEmployeePage}
          />
          <ManagerRoute
            exact
            path="/manager/employee"
            component={ManagerEmployeePage}
          />
          <ManagerRoute
            exact
            path="/manager/employee/:idEmp"
            component={ManagerEmployeeProfilePage}
          />

          {/* Admin Route */}
          <AdminRoute exact path="/admin/setting" component={SettingPage} />
          <AdminRoute exact path="/admin/overview" component={OverviewPage} />
          <AdminRoute
            exact
            path="/admin/approve-list"
            component={ApporveStatusListPage}
          />
          <AdminRoute
            exact
            path="/admin/history-approve-list"
            component={HistoryApproveListPage}
          />
          <AdminRoute
            exact
            path="/employees/:id/view"
            component={EmployeeProfilePage}
          />
          <AdminRoute
            exact
            path="/employees/:id/edit"
            component={KeyAddition}
          />
          <AdminRoute exact path="/admin/employees" component={EmployeesPage} />
          <AdminRoute exact path="/admin/time" component={TimeManagementPage} />
          <AdminRoute
            exact
            path="/admin/employees/register"
            component={AdminEmployeeRegisterPage}
          />
          <AdminRoute
            exact
            path="/admin/shift/list-employee/:idShiftGroup"
            component={ShiftListEmployeePage}
          />
          <AdminRoute
            exact
            path="/admin/shift/new"
            component={ShiftAddNewPage}
          />
          <AdminRoute
            exact
            path="/admin/shift/year/:id"
            component={PlanYearPage}
          />
          <AdminRoute exact path="/admin/shift" component={ShiftPage} />

          <AdminRoute
            exact
            path="/admin/otmonitoring"
            component={AdminOtMonitoring}
          />
          <AdminRoute
            exact
            path="/admin/dashboard/cost"
            component={AdminDashboardCost}
          />
          <AdminRoute
            exact
            path="/admin/dashboard/ot"
            component={AdminDashboardOt}
          />
          <AdminRoute
            exact
            path="/admin/dashboard/workingtime"
            component={AdminDashboardWorkingTime}
          />
          <AdminRoute
            exact
            path="/admin/manpower/:id"
            component={TeamManPowerPage}
          />
          <AdminRoute exact path="/admin/manpower" component={ManPowerPage} />
          <AdminRoute exact path="/admin/request" component={RequestPage} />
          <AdminRoute
            exact
            path="/admin/request/information/:id"
            component={RequestInformationPage}
          />
          <AdminRoute
            exact
            path="/admin/request/form"
            component={RequestFormPage}
          />
          <AdminRoute
            exact
            path="/admin/request/candidate/:idCandidate"
            component={CandidateProfilePage}
          />

          <AdminRoute exact path="/admin/billing" component={BillingPage} />
          <AdminRoute
            exact
            path="/admin/billing/:idBilling"
            component={BillingDetailPage}
          />

          <AdminRoute exact path="/admin/contract" component={ContractVendor} />
          <AdminRoute
            exact
            path="/admin/contract/profile/:selected"
            component={ContractDetailVendor}
          />

          <AdminRoute
            exact
            path="/admin/company-profile"
            component={CompanyProfilePage}
          />

          <AdminRoute exact path="/admin/selectkey/add" component={SelectKey} />
          <AdminRoute
            exact
            path="/admin/courses/:id"
            component={CourseDetailAdmin}
          />
          <AdminRoute exact path="/admin/courses" component={CoursePageAdmin} />
          <AdminRoute
            exact
            path="/admin/access-area/:selected"
            component={AccessAreaDetailAdmin}
          />
          <AdminRoute
            exact
            path="/admin/access-area"
            component={AccessAreaAdmin}
          />
          <AdminRoute
            exact
            path="/admin/summary-time"
            component={AdminSummaryOTPage}
          />
          <AdminRoute
            exact
            path="/admin/contract-dashboard"
            component={ContractDashboard}
          />

          {/* <VendorRoute exact path="/change-password" component={ChangePasswordVendor} /> */}
          <VendorRoute
            exact
            path="/vendor/overview"
            component={OverviewVendor}
          />
          {/* <VendorRoute
            path="/vendor/dashboard"
            component={DashboardVendor}
          /> */}
          <VendorRoute
            exact
            path="/vendor/company-profile"
            component={VendorProfilePage}
          />
          <VendorRoute
            exact
            path="/vendor/setting"
            component={SettingVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/employees"
            component={EmployeePage}
          />
          <VendorRoute
            exact
            path="/vendor/employees/register"
            component={EmployeeRegisterPage}
          />
          <VendorRoute
            exact
            path="/vendor/employees/register_new"
            component={AddEmployeePage}
          />
          <VendorRoute
            exact
            path="/vendor/employees/:id/view"
            component={EmployeeProfilePageVendor}
          />
          <VendorRoute
            exact
            path="/vendor/employees/:id/edit"
            component={KeyAdditionVendor}
          />
          <VendorRoute
            exact
            path="/vendor/leave-absent-late"
            component={LeaveAbsentLate}
          />
          <VendorRoute
            exact
            path="/report-center/summary-ot-shift"
            component={SummaryOTShift}
          />
          <VendorRoute
            exact
            path="/report-center/employees-tax"
            component={DashboardTax}
          />
          <VendorRoute
            exact
            path="/report-center/summary-ot-hours-wages"
            component={DashboardOTList}
          />
          <VendorRoute
            exact
            path="/report-center/quota-leave-list"
            component={QuotaLeaveList}
          />
          <VendorRoute
            exact
            path="/report-center/newEmployees"
            component={NewEmployeesReport}
          />
          <VendorRoute
            exact
            path="/report-center/resign"
            component={ResignEmployeeReport}
          />
          <VendorRoute
            exact
            path="/report-center/contractTermination"
            component={ContractTermination}
          />
          <VendorRoute
            exact
            path="/report-center/probation"
            component={ProbationStatus}
          />
          <VendorRoute
            exact
            path="/report-center/leveling-report"
            component={LevelingReport}
          />
          <VendorRoute
            exact
            path="/report-center/employment-report"
            component={employmentReport}
          />
          <VendorRoute
            exact
            path="/report-center/payroll"
            component={Payroll}
          />
          <VendorRoute
            exact
            path="/report-center/transfer-report"
            component={TransferPosition}
          />
          <VendorRoute
            exact
            path="/report-center/pay-slip"
            component={PayslipAdmin}
          />
          <VendorRoute
            exact
            path="/report-center/provident-fund"    
            component={DashboardProvidentFund}
          />
          <VendorRoute
            exact
            path="/vendor/sourcing"
            component={SourcingVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/request/information"
            component={RequestVendorInformationPage}
          />

          <VendorRoute
            exact
            path="/vendor/payroll/run/new"
            component={PayRunsNewPage}
          />
          <VendorRoute
            exact
            path="/vendor/approve-list"
            component={ApporveStatusListVendorPage}
          />
          <VendorRoute
            exact
            path="/vendor/history-approve-list"
            component={HistoryApporveStatusListVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/time"
            component={TimeManagementVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/time/company/:idCompany/employees/:idEmp"
            component={TimelineEmployeesPage}
          />

          <VendorRoute exact path="/payroll" component={PayRunsPage} />

          <VendorRoute
            exact
            path="/payroll/summary-year/:year"
            component={PayrollSummaryYearPage}
          />

          <VendorRoute
            exact
            path="/payroll/addition-deduction"
            component={PayrollAdditionDeductionPage}
          />

          <VendorRoute
            exact
            path="/payroll/setting"
            component={PayrollSettingPage}
          />

          <VendorRoute
            exact
            path="/vendor/payroll/run/edit"
            component={PayRunsEditPage}
          />

          <VendorRoute
            exact
            path="/vendor/payroll/run/:idPayrun"
            component={PayRunsEditPage}
          />

          <VendorRoute
            exact
            path="/vendor/payroll/run/:idPayrun"
            component={PayRunsEditPage}
          />

          <VendorRoute
            exact
            path="/vendor/billing"
            component={BillingVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/courses"
            component={CourseTrainingVendor}
          />
          <VendorRoute
            exact
            path="/vendor/courses/:id"
            component={CourseDetailVendor}
          />

          <VendorRoute
            exact
            path="/vendor/payroll/addition-deduction"
            component={PayrollAdditionDeductionPage}
          />

          <VendorRoute
            exact
            path="/vendor/billing"
            component={BillingVendorPage}
          />

          <VendorRoute
            exact
            path="/vendor/billing/create/:idPayrun"
            component={CreateBilling}
          />

          <AdminRoute
            exact
            path="/vendor/billing/:idPayrun"
            component={BillingDetailVendorPage}
          />

          <VendorRoute exact path="/vendor/profile" component={ProfileVendor} />

          <VendorRoute
            exact
            path="/vendor/contract"
            component={ContractCompany}
          />
          <VendorRoute
            exact
            path="/contract-employee"
            component={ContractEmployee}
          />
          <VendorRoute
            exact
            path="/contract-employee/new"
            component={AddOrEditContractEmployeeForm}
          />
          <VendorRoute
            exact
            path="/contract-employee/:selected"
            component={ContractDetailEmployee}
          />
          <VendorRoute
            exact
            path="/contract-employee/:selected/edit"
            component={AddOrEditContractEmployeeForm}
          />

          <VendorRoute
            exact
            path="/vendor/contract/profile/:selected"
            component={ContractDetailCompany}
          />

          <VendorRoute exact path="/vendor/shift" component={ShiftVendorPage} />
          <VendorRoute
            exact
            path="/vendor/shift/new"
            component={ShiftAddNewVendorPage}
          />
          <VendorRoute
            exact
            path="/vendor/shift/list-employee/:idShiftGroup"
            component={ShiftListEmployeeVendorPage}
          />
          <VendorRoute
            exact
            path="/vendor/shift/list-employee/:idShiftGroup"
            component={ShiftListEmployeeVendorPage}
          />
          <VendorRoute
            exact
            path="/vendor/shift/year/:id"
            component={PlanYearVendorPage}
          />

          <VendorRoute
            exact
            path="/report-center"
            component={DocumentsVendorPage}
          />
          <VendorRoute
            exact
            path="/employees-summary-time"
            component={SummaryTimeVendorPage}
          />
          {/* <VendorRoute
            exact
            path="/vendor/travel-records"
            component={TravelRecords}
          /> */}

          <VendorRoute
            exact
            path="/vendor/access-area"
            component={AccessAreaVendor}
          />
          <VendorRoute
            path="/employees-documents/:type"
            component={PND91AND50tawi}
          />

          <VendorRoute
            exact
            path="/payroll/tax-summary"
            component={TaxSummaryPage}
          />

          <TrainingRoute exact path="/courses/:id" component={CourseDetail} />
          <TrainingRoute exact path="/courses" component={CoursePage} />

          <VendorManagerUserAccountantRoute
            exact
            path="/expense"
            component={ExpensePage}
          />

          <AccountantRoute
            exact
            path="/expense-export"
            component={ExpenseExport}
          />
          <VendorManagerAccountantRoute
            exact
            path="/expense/detail/:typeExpense/:idExpense/:idEmp/:mode"
            component={ExpenseDetailPage}
          />
          <VendorManagerAccountantRoute
            exact
            path="/expense/history"
            component={ExpenseHistoryPage}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Container>
  );
};

export default Routers;
