import { httpClient } from "./httpClient";

const dumpExcelEmployees = (formData, query) => {
  return httpClient.put(`upload-excel/dumpFile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: query,
  });
};

export default {
  dumpExcelEmployees,
};
