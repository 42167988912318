import {
    GROUPLEVELS_COMPANY_FETCHING,
    GROUPLEVELS_COMPANY_FAILED,
    GROUPLEVELS_COMPANY_SUCCESS,
    LEVELS_FETCHING,
    LEVELS_FAILED,
    LEVELS_SUCCESS,
    PERSONNEL_LEVELS_FETCHING,
    PERSONNEL_LEVELS_FAILED,
    PERSONNEL_LEVELS_SUCCESS,
    LEVELADDITION_FETCHING,
    LEVELADDITION_FAILED,
    LEVELADDITION_SUCCESS,
} from "./types";

import LevelService from "../services/level.service";

export const getAllGroupLevelsByIdCompany = (idCompany) => async (dispatch) => {
    try {
        dispatch({
            type: GROUPLEVELS_COMPANY_FETCHING,
        });
      const res = await LevelService.getAllGroupLevelsByIdCompany(idCompany);
      if (res) {
        dispatch({
          type: GROUPLEVELS_COMPANY_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: GROUPLEVELS_COMPANY_FAILED,
      });
      return err
    }
};

export const updateGroupLevel = (data, idCompany) => async () => {
  try {
    const res = await LevelService.updateGroupLevel(data, idCompany);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateLevel = (data, idCompany) => async () => {
  try {
    const res = await LevelService.updateLevel(data, idCompany);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addLevel = (data) => async () => {
  try {
    const res = await LevelService.addLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const addGroupLevel = (data) => async () => {
  try {
    const res = await LevelService.addGroupLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const switchLevel = (data) => async () => {
  try {
    const res = LevelService.switchLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const updateJobLevel = (idJobLevel, data) => async () => {
  try {
    const res = await LevelService.updateJobLevel(idJobLevel, data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const deleteGroupLevel = (idGroupLevel) => async () => {
  try {
    const res = await LevelService.deleteGroupLevel(idGroupLevel);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const deleteLevel = (idLevel) => async () => {
  try {
    const res = await LevelService.deleteLevel(idLevel);
    if (res) {
      console.log("res: ", res)
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const getLevelAdditionsByidJobLevel = (idJobLevel) => async (dispatch) => {
  try {
      dispatch({
          type: LEVELADDITION_FETCHING
      });
      let res = await LevelService.getLevelAdditionsByidJobLevel(idJobLevel);
      if(res){
          dispatch({
              type: LEVELADDITION_SUCCESS,
              payload: res.data
          });
      }
  } catch (error) {
      dispatch({
          type: LEVELADDITION_FAILED
      });
  }
};
