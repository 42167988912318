import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { Box, Grid, Link, Snackbar, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getAllLocations, clearLocations } from "../../../../../../actions/gpsLocations";

import ListMap from "./listMap";
import DialogGroupGpsLocations from "./DialogGroupGpsLocations";
import DialogFormGpsPositions from "./FormGpsPositions";

const useStyles = makeStyles((theme) => ({
  locations_container: {
    marginTop: theme.spacing(2),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  locationCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  infoLocationBox: {
    position: "relative",
    paddingLeft: theme.spacing(4),
    height: "100%",
  },
  lastUpdateBox: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  locationNameBox: {
    marginBottom: theme.spacing(2),
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  display: "flex",
  flexWrap: "wrap",
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledRoot = styled("div")({
  marginTop: 28,
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "0.9rem",
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
});
const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
});

function LocationList(props) {
  const { company } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: locationsList } = useSelector((state) => state.locations);
  const [expanded, setExpanded] = React.useState("");
  const [openDialogGroupGpsLocations, setOpenDialogGroupGpsLocations] =
    React.useState(false);
  const [openDialogFormGpsPositions, setOpenDialogFormGpsPositions] =
    React.useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mode, setMode] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenDialogGroupGpsLocations = () => {
    setOpenDialogGroupGpsLocations(true);
  };

  const handleOpenDialogAddGpsPositions = (selected) => (event) => {
    event.stopPropagation();
    setMode("add");
    setSelectedGroup(selected);
    setOpenDialogFormGpsPositions(true);
  };

  const handleOpenDialogEditGpsPositions = (selected) => (event) => {
    setMode("edit");
    setSelectedGroup(selected);
    setOpenDialogFormGpsPositions(true);
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenDialogGroupGpsLocations(false);
    setOpenDialogFormGpsPositions(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert("");
  };

  useEffect(() => {
    if (company) {
      dispatch(getAllLocations(company.idCompany));
    }
  }, [company]);

  return (
    <StyledRoot className={classes.root}>
      <Box style={{ marginBottom: 16 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{t("AreaPositionList")}</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              disabled={!company}
              variant={"contained"}
              onClick={handleOpenDialogGroupGpsLocations}
            >
              {t("AddNewArea")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      {locationsList && locationsList.length > 0 ? (
        <div>
          {locationsList.map((value, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div className="wrap">
                  <Typography>{`${value.name}`}</Typography>
                  <div>
                    <ButtonBlue
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleOpenDialogAddGpsPositions(value)}
                    >
                      {t("Position")}
                    </ButtonBlue>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {value.locations &&
                  value.locations.map((item, index) => (
                    <Box style={{ width: 300, margin: 8 }}>
                      <Link
                        onClick={handleOpenDialogEditGpsPositions(item)}
                        underline="hover"
                        color="inherit"
                      >
                        <Typography align="center" variant="h6">
                          {item.name}
                        </Typography>
                      </Link>
                      <ListMap
                        id={`map-${item.name}-${index}`}
                        positions={item.positions}
                      />
                      <Typography
                        align="right"
                        variant="subtitle1"
                        style={{ color: "#9e9e9e" }}
                      >
                        {t("LastUpdate")} :{" "}
                        {item.editDate
                          ? dayjs(item.editDate).format("DD MMM YYYY")
                          : dayjs(item.createDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <StyledEmpty className="empty-holiday">
          <Typography color="text.secondary" align="center">
            {t("No_Data")}
          </Typography>
        </StyledEmpty>
      )}
      <DialogGroupGpsLocations
        company={company}
        open={openDialogGroupGpsLocations}
        handleClose={handleClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      <DialogFormGpsPositions
        mode={mode}
        company={company}
        selectedGroup={selectedGroup}
        open={openDialogFormGpsPositions}
        handleClose={handleClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {alertType === "success" ? (
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            style={{ width: "100%" }}
          >
            {t("Save_data_successfully")}
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            style={{ width: "100%" }}
          >
            {t("Please_Contact_Admin")}
          </Alert>
        )}
      </Snackbar>
    </StyledRoot>
  );
}

export default LocationList;
